import '@/styles/global.css';

import { GoogleAnalytics, GoogleTagManager } from '@next/third-parties/google';
import { IconContext } from '@phosphor-icons/react';
import type { AppProps, NextWebVitalsMetric } from 'next/app';
import { Inter } from 'next/font/google';
import { appWithTranslation } from 'next-i18next';

const fontFamily = Inter({
  subsets: ['latin'],
  display: 'block',
  variable: '--font-inter',
});

export function reportWebVitals({
  name,
  label,
  value,
  id,
}: NextWebVitalsMetric) {
  if (window.gtag) {
    window.gtag('event', name, {
      event_category:
        label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
      value: Math.round(name === 'CLS' ? value * 1000 : value),
      event_label: id,
      non_interaction: true,
    });
  }
}

const MyApp = ({ Component, pageProps }: AppProps) => {
  return (
    <>
      <main className={`${fontFamily.variable} font-sans`}>
        <IconContext.Provider value={{ weight: 'bold', size: 24 }}>
          <Component {...pageProps} />
        </IconContext.Provider>
      </main>
      <GoogleTagManager gtmId="GTM-M92GMJ7L" />
      <GoogleAnalytics gaId="G-X7P0JJSMPR" />
    </>
  );
};

export default appWithTranslation(MyApp);
